var winheight = window.innerHeight;
var app;

function App() {
	var self = this;
	this.foundation = $(document).foundation({
		equalizer : {
			equalize_on_stack: false
		}
	});
	$(document).foundation('joyride','start');

	// var options = {
 //        debug: true,
 //        prefetch: true,
 //        pageCacheSize: 2,
 //        onStart: {
 //          duration: 500, // Duration of our animation
 //          render: function ($container) {
 //            // Add your CSS animation reversing class
 //            $container.addClass('is-exiting');
			
 //            // Restart your animation
 //            self.smoothState.restartCSSAnimations();
 //          }
 //        },
 //        onAfter: function($container, $newContent) {
 //            $(document).foundation("interchange", "reflow");
 //        },
 //        onReady: {
 //          duration: 500,
 //          render: function ($container, $newContent) {
 //            // Remove your CSS animation reversing class
 //            // Inject the new content
 //            $container.html($newContent);
 //            self.init();
 //            $container.removeClass('is-exiting');
 //          }
 //        }
 //      };
	// this.smoothState = $('#main-content').smoothState(options).data('smoothState');
	
	this.touch = Modernizr.touch;
	this.homepage = false;
	this.carousel = false;
	this.video = false;
	this.map = false;
	// this.ajaxify = new Ajaxify(this);
	this.init();
};

App.prototype.init = function() {
	if ($('.carousel').length)
		this.carousel = new Carousel();
	if ($('#map').length) {
		this.map = new Map();
	}
	if ($('.carousel video').length) {
		this.video = $('.carousel video');
		this.handleVideo();
	}
	this.cookies = new Cookies();
	this.handleEmployeeBox();
	this.handleFixed();
	this.handleTap();
	this.handleMenu();
	this.handleJobOffers();
	this.setComponentHeights();
	$(window).on('resize', this, this.handleWindowResize);
	PointerEventsPolyfill.initialize({});
};

App.prototype.destroy = function() {
};

(function(window,undefined){
    History.Adapter.bind(window,'statechange',function(){ // Note: We are using statechange instead of popstate
        var State = History.getState(); // Note: We are using History.getState() instead of event.state
    });
})(window);

App.prototype.handleVideo = function() {
	var self = this;
	self.video.on("canplaythrough", function(e) {
		self.video.addClass('active');
		self.video[0].play();
	});
	self.video.on("timeupdate",function(e) {
		if (self.video[0].duration - self.video[0].currentTime < 0.9) {
			self.video.css('opacity',0);
			// self.carousel.instances[0].slideNext();
			// self.video[0].currentTime = 9.5;
		}
	});
}

App.prototype.handleEmployeeBox = function() {
	$('.employee-box').each(function() {
		var video = $(this).find('video');
		if (video.length) {
			$(this).on('mouseover',function() {
				video.css('opacity',1);
				video[0].play();
			});
			$(this).on('mouseout',function() {
				video.css('opacity',0);
				video[0].pause();
				video[0].currentTime = 0;
			});
			video.on("timeupdate",function(e) {
				if (video[0].duration - video[0].currentTime < 0.4) {
					video.css('opacity',0);
				}
			});
		}
	});
}

App.prototype.handleFixed = function() {
	$('.fixed').each(function() {
		var parent = $(this).parent();
		$(this).css({width:parent.innerWidth(),height:parent.innerHeight()+"px"});
	});
};

App.prototype.handleTap = function() {
	var self = this;
	// if (self.touch) {
	// 	$('figure').on('tap',function(e) {
	// 		$(this).siblings().removeClass('active');
	// 		$(this).toggleClass('active');
	// 	});
	// 	$('figure').on('click',function(e) {
	// 		e.stopPropagation();
	// 	});
	// 	$('figure a').click(function(e) {
	// 		e.preventDefault();
	// 	});
	// }
};

App.prototype.setComponentHeights = function() {
	var self = this;
	// $('.page-header .page-header-image').height(winheight*1);
	// $('.article-header .article-header-image, .casestudy-header').height(winheight*1);
	$('.banner-start').height(winheight);
	$('.square, .no-touch .employee-box, .no-touch .usp-box').each(function() {
		// $(this).height(Math.floor($(this)[0].getBoundingClientRect().width));
	});
	// $('.maxheight > *').css('display','none');
	// var mainheight = $('main').innerHeight();
	// $('.maxheight > *').css('height',Math.floor(mainheight/2));
	// $('.maxheight > *').css('display','block');
};

App.prototype.handleWindowResize = function(e) {
	winheight = window.innerHeight;
	e.data.handleFixed();
	e.data.setComponentHeights();
};

App.prototype.handleMenu = function(e) {
	var self = this;
	if (self.touch) {
		$('.menu-show-icon').on('touchstart click', function(e) {
			e.preventDefault();
			e.stopPropagation();
			$('body').toggleClass('show-menu');
		});
		$('.menu-close-icon').on('touchstart click', function(e) {
			e.preventDefault();
			e.stopPropagation();
			$('body').removeClass('show-menu');
		});
	} else {
		$('.menu-show-icon').on('mouseenter', function(e) {console.log('enter');
			e.preventDefault();
			$('body').toggleClass('show-menu');
		});
		$('.menu-close-icon').on('click', function(e) {console.log('click');
			e.preventDefault();
			$('body').removeClass('show-menu');
		});
		$('nav').on('mouseleave', function(e) {
			e.preventDefault();
			$('body').removeClass('show-menu');
		});
	}
};

App.prototype.handleJobOffers = function(e) {
	$('.joboffers figure:not(.active)').each(function() {
		$(this).find('p').empty().append('Teraz nie szukamy nikogo na to stanowisko, ale możesz nam wysłać swoje CV, najlepszym nigdy nie odmawiamy!');
		$(this).find('a').empty().append('Wyślij CV');
		$(this).find('a').attr('href','mailto:kariera@lyrebird.pl?subject=Zostaw CV');
	});
};

$(document).ready(function() {
	app = new App();
	if(navigator.userAgent.match(/OS 7/)) {
		$('html').addClass("ios7");
	}
});

// window.onpageshow = function(event) {
//     if (event.persisted) {
//         window.location.reload() 
//     }
// };