function Ajaxify(target) {
	this.history = window.History;
	this.target = target;
	if (!this.history.enabled) {
		return false;
	} else {
		this.init();
	}
}

Ajaxify.prototype.init = function() {
	this.contentSelector = "main";
	this.menuSelector = "header nav";
	this.activeClass = 'active';
	this.activeSelector = '.active';
	this.menuChildrenSelector = ' li, ul > li';
	this.completedEventName = 'statechangecomplete';

	this.body = $(document.body);
	this.window = $(window);
	this.document = window.document;
	this.content = $(this.contentSelector).filter(':first');
	this.contentNode = this.content.get(0);
	this.menu = $(this.menuSelector);

	this.rootUrl = this.history.getRootUrl(),
    this.scrollOptions = {
        duration: 800,
        easing:'swing'
    };
    this.run(); 
};

Ajaxify.prototype.run = function() {
	if (this.content.length === 0 ) {
        this.content = this.body;
    }
    this.body.ajaxify();
    this.window.bind('statechange', this, this.handleStateChange);
};

Ajaxify.prototype.handleStateChange = function(e) {
	e.preventDefault();
	self = e.data;
	var
        State = self.history.getState(),
        url = State.url,
        data = State.data.data||"",
        method = State.data.method||"GET",
        relativeUrl = url.replace(self.rootUrl,'');
        
	self.body.addClass('loading');
	self.target.destroy();
	$.ajax({
        url: url,
        data: data,
        type: method,
        success: function(data, textStatus, jqXHR){
        	// Prepare
            var
                $data = $(self.documentHtml(data)),
                $dataBody = $data.find('[data-ajaxify="document-body"]:first'),
                $dataContent = $dataBody.find(self.contentSelector).filter(':first'),
                $menuChildren, contentHtml, $scripts;
            
            // Fetch the scripts
            $scripts = $dataContent.find('[data-ajaxify="document-script"]');
            if ( $scripts.length ) {
                $scripts.detach();
            }

            // Fetch the content
            contentHtml = $dataContent.html()||$data.html();
            if ( !contentHtml ) {
                document.location.href = url;
                return false;
            }
            
            // Update the menu
            $menuChildren = self.menu.find(self.menuChildrenSelector);
            $menuChildren.filter(self.activeSelector).removeClass(self.activeClass);
            $menuChildren = $menuChildren.has('a[href^="'+relativeUrl+'"],a[href^="/'+relativeUrl+'"],a[href^="'+url+'"]');
            $menuChildren.addClass(self.activeClass);

            // Update the content
            self.content.stop(true,true);
            self.content.html(contentHtml).ajaxify();
            self.body.attr('class',$data.find('[data-ajaxify="document-body"]:first').attr('class')+" loading");
            self.target.init();
            if (self.content.find('.formSubmit').length)
                ga('send', 'pageview', '/formularz-kontaktowy');

            // Update the title
            self.document.title = $data.find('[data-ajaxify="document-title"]:first').text();
            var description = $data.find('[data-ajaxify="document-meta"][name="description"]').attr('content');
            var keywords = $data.find('[data-ajaxify="document-meta"][name="keywords"]').attr('content');

            try {
                console.log(description,keywords);
                self.document.getElementsByTagName('title')[0].innerHTML = self.document.title.replace('<','&lt;').replace('>','&gt;').replace(' & ',' &amp; ');
                $(document).find('meta[name="description"]').attr('content', description.replace('<','&lt;').replace('>','&gt;').replace(' & ',' &amp; '));
                $(document).find('meta[name="keywords"]').attr('content', keywords.replace('<','&lt;').replace('>','&gt;').replace(' & ',' &amp; '));
            }
            catch ( Exception ) { }
            
            // Add the scripts
            $scripts.each(function(){
                var $script = $(this), scriptText = $script.text(), scriptNode = self.document.createElement('script');
                if ( $script.attr('src') ) {
                    if ( !$script[0].async ) { scriptNode.async = false; }
                    scriptNode.src = $script.attr('src');
                }
                    scriptNode.appendChild(document.createTextNode(scriptText));
                contentNode.appendChild(scriptNode);
            });

            // Complete the change
            if ( self.body.ScrollTo||false ) { self.body.ScrollTo(self.scrollOptions); } /* http://balupton.com/projects/jquery-scrollto */

            // Inform Google Analytics of the change
            if ( typeof window._gaq !== 'undefined' ) {
                window._gaq.push(['_trackPageview', relativeUrl]);
            }

            // Inform ReInvigorate of a state change
            if ( typeof window.reinvigorate !== 'undefined' && typeof window.reinvigorate.ajax_track !== 'undefined' ) {
                reinvigorate.ajax_track(url);
                // ^ we use the full url here as that is what reinvigorate supports
            }
            
            self.content.waitForImages({finished: function() {
	            self.body.removeClass('loading');
            	self.window.trigger(self.completedEventName);
            },waitForAll: true});
        },
        error: function(jqXHR, textStatus, errorThrown){
            document.location.href = url;
            return false;
        }
    }); // end ajax
};

Ajaxify.prototype.documentHtml = function(html){
    var result = String(html)
        .replace(/<\!DOCTYPE[^>]*>/i, '')
        .replace(/<(html|head|body|title|meta|script)([\s\>])/gi,'<div data-ajaxify="document-$1"$2')
        .replace(/<\/(html|head|body|title|meta|script)\>/gi,'</div>')
    ;
    return $.trim(result);
};

// Ajaxify Helper
$.fn.ajaxify = function(){
    var $this = $(this);
    $this.find('a[href]').bind('click',function(){
        var href = $(this).attr('href');
        var mail = /^mailto:(.*)zora-wilanow\.pl$/;
        var tel = /^tel:(.*)/;
        if (mail.exec(href))
            ga('send', { 'hitType': 'event', 'eventCategory': 'Zora', 'eventAction': 'Mail'});
        if (tel.exec(href))
            ga('send', { 'hitType': 'event', 'eventCategory': 'Zora', 'eventAction': 'Telefon'});
    });
    $this.find('a:internal:not(.no-ajaxy), area:not(.no-ajaxy)').click(function(event){
        var
            $this = $(this),
            url = $this.attr('href'),
            title = $this.attr('title')||null;
        
        // $this.closest('.navbar-collapse').collapse('hide');

        // Continue as normal for cmd clicks etc
        if ( event.which == 2 || event.metaKey ) { return true; }
        
        // Ajaxify this link
        History.pushState(null,title,url);
        event.preventDefault();
        return false;
    });
    $this.find('form:not(.no-ajaxy)').bind('submit',function(event){
        var
            $this = $(this),
            url = $this.attr('action')||"",
        	method = $this.attr('method')||"GET",
        	data = $this.serialize(),
            title = $this.attr('title')||null;
		
		if (method == "GET")
			History.pushState({method: "GET", data: data},title,url+"?"+data);
		else
			History.pushState({method: "POST", data: data},title,url);
    	event.preventDefault();
    });
    return $this;
};

// Internal Helper
$.expr[':'].internal = function(obj, index, meta, stack){
    // Prepare
    var
        $this = $(obj),
        url = $this.attr('href')||'',
        isInternalLink,
        rootUrl = window.History.getRootUrl();
    
    // Check link
    isInternalLink = url.substring(0,rootUrl.length) === rootUrl || url.indexOf(':') === -1;
    
    // Ignore or Keep
    return isInternalLink;
};