function Cookies(options) {
    this.defaults = {
        target: $(".cookieinfo"),
        timeout: 10000,
        cookiename: "cookies_info",
    }
    $.extend(this.defaults, options);
    this.init();
}

Cookies.prototype.init = function() {
    var self = this;
    if (!self.readCookie(self.defaults.cookiename)) {
        self.showInfo();
        $(self.defaults.target).click(function () {self.hideInfo();});
        setInterval(function () {self.hideInfo();}, self.defaults.timeout);
    }
};

Cookies.prototype.createCookie = function(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
};

Cookies.prototype.readCookie = function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
};

Carousel.prototype.eraseCookie = function(name) {
    var self = this;
    self.createCookie(name,"",-1);
};

Cookies.prototype.showInfo = function() {
    var self = this;
    $(self.defaults.target).addClass('active');
};

Cookies.prototype.hideInfo = function() {
    var self = this;
    $(self.defaults.target).removeClass('active');
    self.createCookie(self.defaults.cookiename,"1",self.defaults.timeout);
};