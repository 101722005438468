function Map() {
	this.instances = [];
  	this.mapOptions = {
		default: {
			center:new google.maps.LatLng(52.158139, 21.075611),
			zoom: 17,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			panControl: false,
			zoomControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			overviewMapControl: false,
			markerIcon: "/page/css/images/map-marker.png",
			markerIcon2: "/page/css/images/biuro-map-marker.png",
  		},
    };
	this.init();
}

Map.prototype.init = function() {
	var self = this;
  	$('#map').each(function() {
  		self.createInstance(this);
  	});
  	$(window).on('resize', this, this.handleWindowResize);
};

Map.prototype.createInstance = function(scope) {
	var self = this;
	var options = $.extend({},self.mapOptions['default'],self.mapOptions[$(scope).attr("data-type")]);
	var map = new google.maps.Map(scope, options);
	map.addMarker(52.158939, 21.076511, map.markerIcon);
	map.addMarker(52.158139, 21.075611, map.markerIcon2);
  	self.instances.push(map);
};

google.maps.Map.prototype.addMarker = function(lat, lng, marker) {
	var self = this;
	var latlng = new google.maps.LatLng(lat, lng);
	var marker = new google.maps.Marker({
		position: latlng,
		map: this,
		icon: marker,
	});
};

Map.prototype.handleWindowResize = function(e) {
	var self = e.data;
};