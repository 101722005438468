function Carousel() {
	this.instances = [];
  	this.swiperOptions = {
		default: {
			grabCursor: false,
	    	direction:'horizontal',
	    	slidesPerView: 1,
	    	centeredSlides: true,
	    	autoplay: 5000,
    		speed: 1000,
    		loop: true,
    		loopAdditionalSlides: 3,
    		longSwipesRatio: 0.25,
	    	keyboardControl: true,
    		pagination: '.pagination',
    		paginationClickable: true,
    		preloadImages: false,
    		lazyLoading: true,
    		onTouchStart: function() {
				allowClick = true;
			},
			onTouchMove: function() {
				allowClick = false;
			},
			onTouchEnd: function() {
				setTimeout(function(){allowClick = true;},100);
			},
  		},
  		banners: {
  			direction:'vertical',
  			autoplay: false,
  			loop: false,
			effect: 'coverflow',
			coverflow: {
			  rotate: 0,
			  stretch: 10,
			  depth: 250,
			  modifier: 1,
			  slideShadows: false,
			},
			onInit: function(swiper) {
				swiper.params.detectScroll(swiper);
			},
			detectScroll: function(swiper) {
				swiper.wheelEvent = false;
				if (document.onmousewheel !== undefined)
                	swiper.wheelEvent = 'mousewheel';
                if (!swiper.wheelEvent) {
	                try {
	                    new window.WheelEvent('wheel');
	                    swiper.wheelEvent = 'wheel';
	                } catch (e) {}
	            }
	            if (!swiper.wheelEvent)
	                swiper.wheelEvent = 'DOMMouseScroll';
				$(swiper.container).on(swiper.wheelEvent,swiper,swiper.params.onMouseWheel);
			},
			onMouseWheel: function(e) {
				var self = e.data;
				var delta = 0;
				var e = e.originalEvent;
				if (self.wheelEvent == 'mousewheel') {
					delta = e.wheelDeltaY;
				} else if (self.wheelEvent == 'wheel'){
					delta = -e.deltaY;
				} else {
					delta = -e.detail;
				}
				if (delta > 0)
					self.slidePrev();
				else
					self.slideNext();
				console.log(delta);
			},
			onSlideChangeStart: function(swiper) {
				var previous = swiper.slides[swiper.previousIndex];
				$(swiper.container).off(swiper.wheelEvent);
				$('body').attr('data-color',swiper.activeIndex);
	    		$('body').attr('data-header-color',0);
	    		if ($(previous).find('video').length) {
	    			$(previous).find('video')[0].pause();
	    		}
	    	},
	    	onSlideChangeEnd: function(swiper) {
	    		var next = swiper.slides[swiper.activeIndex];
	    		var previous = swiper.slides[swiper.previousIndex];
	    		if ($(next).find('video').length) {
	    			$(next).find('video').css('opacity',1);
	    			$(next).find('video')[0].play();
	    		}
	    		$('body').attr('data-header-color',swiper.activeIndex);
	    		$(swiper.container).on(swiper.wheelEvent,swiper,swiper.params.onMouseWheel);
	    	},
  		}
    };
	this.init();
}

Carousel.prototype.init = function() {
	var self = this;
  	$('.carousel').each(function(){
  		self.createInstance($(this));
	});
  	$(window).on('resize', this, this.handleWindowResize);
};

Carousel.prototype.createInstance = function(scope) {
	var self = this;
	var options = $.extend({},self.swiperOptions['default'],self.swiperOptions[scope.attr("data-type")]);
	if (options.pagination)
		options.pagination = scope.find(options.pagination)[0];
	var swiper = scope.find('.swiper-container').swiper(options);
    scope.find('.swiper-prev').click(function(){
  		swiper.swipePrev();
  	});
  	scope.find('.swiper-next').click(function(){
  		swiper.swipeNext();
  	});
  	scope.find('.cta[href=#]').click(function(e){
		e.preventDefault();
		swiper.slideNext();
	});
  	self.instances.push(swiper);
};

Carousel.prototype.handleScroll = function (e) {
	var self = e.data;

}

Carousel.prototype.handleSlide = function (e) {
	var slide = $(e.activeSlide());
};

Carousel.prototype.handleWindowResize = function(e) {
	var self = e.data;
};