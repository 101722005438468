function Form() {
	this.init();
}

Form.prototype.init = function () {
	$('form.ajax').submit( function (e) {
		e.preventDefault();
		var self	 = $(this);
		var url 	 = $(this).attr('action');
		var formData = $( this ).serialize();
		self.find('input').attr('disabled','disabled');
		$.ajax({
			type        : 'POST',
			url         : url,
			data        : formData,
			dataType    : 'json',
		}).done(function(data) {
			self.find('div.error').removeClass("error");
			//self.find('span.errorinfo').html("");
			if (data.fails) {
				for (var name in data.errors) {
					var span = self.find('span[data-form='+name+']');
					span.html(data.errors[name]).addClass('errorinfo').parent().addClass('error');
				}
				self.find('input').removeAttr('disabled');
			}
			else {
				if (data.redirect) {
					self.closest('.contact-form').addClass('thankyou');
					//window.location = data.redirect;
				}
				else {
					self.after('<h3>Wysłano poprawnie formularz<br>Dziejujemy!</h3>');
					self.remove();
				}
			}
		});
	});
}

$(document).ready(function() {
	var form;
	form = new Form();
});